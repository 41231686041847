img {
    border-radius: 8px;
    width: 350px;
    display:block;
    margin: 0 auto;
    margin-top: 80px;
}

@import url('https://fonts.googleapis.com/css2?family=Arvo&family=Creepster&display=swap');

.about {
    display: flex;
    flex-direction: column;
    font-size: 40px;
    font-family: 'Arvo', serif;
    align-items: center;
    justify-content: center;
    color: white;
    height: 100%;
    width: 100%;
    padding: 5%;
}

.abt{
    height: 100vh;
    width: 100%;
    /* background-image: url("../../images/blankbg.png"); */
    background-size: cover;
    display: flex;
    overflow: scroll;
} 

@media only screen and (max-width: 425px){
    .about {
       text-align: center;
       font-size: 20px;
    }
}