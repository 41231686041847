.home {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    animation-name: bg;
    animation-duration: 40s;
    animation-iteration-count: infinite;
}

@keyframes bg {
    0% {
        background-image: url(./image1.png);
    }

    50% {
        background-image: url(./image2.png);
    }

    100% {
        background-image: url(./image3.png);
    }
}


.name {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 20rem;
    font-family: 'creepster', serif;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-shadow: 0 0 7px #fff, 0 0 10px #fff;
}


@media screen and (max-width: 864px) {
    .pr {
        font-size: 15rem;
    }
}

@media screen and (max-width: 650px) {
    .pr {
        font-size: 10rem;
    }
}

@media screen and (max-width: 450px) {
    .pr {
        font-size: 8rem;
    }
}